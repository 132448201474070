"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.testsConfig = exports.AB_TESTS_CONFIG = void 0;
exports.AB_TESTS_CONFIG = {
    test_manual_exposure: {
        variants: {
            control: { ab_test_variation: 'control' },
            test: {
                ab_test_variation: 'test'
            }
        },
        controlVariantName: 'control',
        shouldAffectBuild: true,
        pages: ['/']
    },
    paid_webflow: {
        variants: {
            control: { ab_test_variation: 'control' },
            webflow: {
                ab_test_variation: 'webflow'
            }
        },
        controlVariantName: 'control',
        shouldAffectBuild: false,
        pages: ['/marketing-consultant-software'],
        webflowPath: '/p/marketing-consultant-software'
    },
    adobe_abc_test: {
        variants: {
            control: {
                ab_test_variation: 'control'
            },
            adobeB: {
                ab_test_variation: 'adobeB'
            },
            adobeC: {
                ab_test_variation: 'adobeC'
            }
        },
        controlVariantName: 'control',
        shouldAffectBuild: true,
        pages: ['/adobe']
    },
    referral_recipient_test: {
        variants: {
            control: { ab_test_variation: 'control' },
            recipient: {
                ab_test_variation: 'recipient',
                redirectPath: '/signup'
            }
        },
        controlVariantName: 'control',
        shouldAffectBuild: false,
        pages: ['/invites']
    },
    public_template_recipient_funnel: {
        variants: {
            control: {
                ab_test_variation: 'control'
            },
            recipient: { ab_test_variation: 'recipient' }
        },
        controlVariantName: 'control',
        shouldAffectBuild: false,
        pages: [/\/template[-_]preview/],
        developerLogsExposure: true
    },
    home_hero_text: {
        variants: {
            control: {
                ab_test_variation: 'control'
            },
            test: { ab_test_variation: 'test' }
        },
        controlVariantName: 'control',
        shouldAffectBuild: true,
        pages: ['/']
    }
};
exports.testsConfig = {
    ...exports.AB_TESTS_CONFIG
};
