import { useSubscribeToIframeEvent } from '../RecipientPage/useSubscribeToIframeEvent';
import { PublicTemplateIframe } from './PublicTemplateIframe';

export const FullScreenPublicTemplate = () => {
    useSubscribeToIframeEvent({
        eventName: 'click_use_template',
        subscriber: ({ data: { signupUrl } }) => {
            if (signupUrl) {
                window.location.href = signupUrl;
            }
        }
    });

    return (
        <div className="template-preview__full-screen-preview">
            <PublicTemplateIframe />
        </div>
    );
};
