import { useCallback, useEffect, useRef, useState } from 'react';
import { useSubscribeToIframeEvent } from './useSubscribeToIframeEvent';
import { TScreenMode } from '../PublicTemplatePreview/type';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useRecipientPageContext } from './context/recipient-page-context';
import AnalyticsService from '../../Services/AnalyticsService';
import { useABTestContext } from '../../ab-tests/context/useABTestContext';

const ANIMATION_PROPERTIES = [
    'top',
    'bottom',
    'right',
    'left',
    'width',
    'height'
] as const;

export const useRecipientPage = () => {
    const [screenMode, setScreenMode] = useState<TScreenMode>('Desktop');
    const [isIframeLoaded, setIsIframeLoaded] = useState(false);
    const [signupRedirectUrl, setSignupRedirectUrl] = useState('');
    const iframeContainerRef = useRef<HTMLDivElement>(null);
    const { postMessageToChild, templateData } = useRecipientPageContext();
    const { largeUp } = useBreakpoint();
    const resolveRef = useRef<(value?: unknown) => void>();

    const {
        variantPerTest: { public_template_recipient_funnel }
    } = useABTestContext();

    const onViewSelect = useCallback((_screenMode: TScreenMode) => {
        setScreenMode(_screenMode);
        AnalyticsService.track('change public template preview screen mode', {
            screen_mode: _screenMode
        });
    }, []);

    const handlePreviewTemplate = useCallback(() => {
        if (!isIframeLoaded) {
            return;
        }
        AnalyticsService.track('click: preview template', {
            templateId: templateData._id
        });
        postMessageToChild({ event: 'preview_clicked' });
        const iframeContainerElement = iframeContainerRef.current!;
        const boundingProperties =
            iframeContainerElement.getBoundingClientRect();
        ANIMATION_PROPERTIES.forEach(property => {
            const { [property]: value } = boundingProperties;
            iframeContainerElement.style[property] = `${value}px`;
        });

        requestAnimationFrame(() => {
            iframeContainerElement.classList.add('full-screen-preview');
            iframeContainerElement.classList.add('visible');
        });
    }, [postMessageToChild, isIframeLoaded, templateData]);

    const onBackButtonClick = useCallback(() => {
        const iframeContainerElement = iframeContainerRef.current!;
        iframeContainerElement.classList.remove('visible');
        setTimeout(
            () => {
                iframeContainerElement.classList.remove('full-screen-preview');
                ANIMATION_PROPERTIES.forEach(property => {
                    requestAnimationFrame(() => {
                        iframeContainerElement.style.removeProperty(property);
                    });
                });
            },
            largeUp ? 450 : 0
        );
    }, [largeUp]);

    useSubscribeToIframeEvent({
        eventName: 'back_button_clicked',
        subscriber: onBackButtonClick
    });

    useSubscribeToIframeEvent({
        eventName: 'click_use_template',
        subscriber: ({ data: { signupUrl } }) => {
            if (signupUrl) {
                window.location.href = signupUrl;
            }
        }
    });

    useSubscribeToIframeEvent({
        eventName: 'loaded',
        subscriber: () => {
            AnalyticsService.track('public template iframe loaded');
            setIsIframeLoaded(true);
            postMessageToChild({
                event: 'template_data',
                data: {
                    isInIframePreview:
                        public_template_recipient_funnel === 'recipient' &&
                        !templateData.public_configuration.is_gallery_template
                }
            });
        }
    });
    useSubscribeToIframeEvent({
        eventName: 'signup_redirect_url_update',
        subscriber: ({ data: { signupRedirectUrl } }) => {
            setSignupRedirectUrl(signupRedirectUrl);
        }
    });

    useEffect(() => {
        if (!!signupRedirectUrl && resolveRef.current) {
            resolveRef.current();
        }
    }, [signupRedirectUrl]);

    const handleGetTemplate = useCallback(() => {
        if (!isIframeLoaded) {
            return;
        }
        let eventName = 'get template in public template';
        if (!templateData.public_configuration.allow_save) {
            eventName = 'start trial from public template';
        }
        AnalyticsService.track(`click: ${eventName}`, {
            action: 'click',
            template_id: templateData._id,
            source: 'cta',
            screen_mode: screenMode
        });
        postMessageToChild({ event: 'get_template_clicked' });
        return new Promise(resolve => {
            resolveRef.current = resolve;
        });
    }, [postMessageToChild, isIframeLoaded, screenMode, templateData]);

    return {
        handleGetTemplate,
        handlePreviewTemplate,
        onViewSelect,
        screenMode,
        signupRedirectUrl,
        iframeContainerRef,
        isIframeLoaded
    };
};
